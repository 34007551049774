<template>
    <el-menu
        class="sidebar__nav"
        @open="onSubMenuOpen"
    >
        <div
            v-for="(navItem, navIndex) in navElements"
            :key="navIndex"
        >
            <div
                v-if="navItem.name === 'База знаний'"
                class="sidebar__nav-item"
                @contextmenu.prevent.stop="onOpenMaterialContextMenu($event, navItem)"
            >
                <nav-tree-material
                    :is-data="navItem"
                    :is-index="`${navIndex}`"
                    :is-open="materialSubMenuOpen"
                />
            </div>

            <nav-tree-doc
                v-else-if="navItem.name === 'Документы'"
                :is-data="navItem"
                :is-index="`${navIndex}`"
                :is-open="docSubMenuOpen"
            />

            <el-submenu
                v-else
                :index="`${navIndex}`"
                :disabled="navItem.children == undefined || navItem.children.length === 0"
                class="sidebar__nav-item"
            >
                <template slot="title">
                    <nav-link
                        :data="Object.assign(
                        {
                            name: navItem.name || navItem.title,
                            link: navItem.link,
                        },
                        navItem
                    )"
                    />
                </template>

                <el-menu-item-group>
                    <el-menu-item
                        v-for="(navChild, childIndex) in navItem.children"
                        :key="childIndex"
                        :index="`${navIndex}-${childIndex}`"
                        class="sidebar__nav-item"
                    >
                        <nav-link
                            :data="Object.assign(
                            {
                                name: navChild.name || navChild.title,
                                link: navChild.link
                            },
                            navChild
                        )"
                        />
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
        </div>

        <teleport to="body">
            <context-menu
                :elementId="materialContextMenu.id"
                :options="materialContextMenu.options"
                ref="materialContextMenu"
                @option-clicked="onMaterialContextMenuClick"
            />
        </teleport>
    </el-menu>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'Navigation',
        components: {
            NavLink: () => import('./NavLink'),
            NavTreeDoc: () => import('../tree/NavTreeDoc'),
            NavTreeMaterial: () => import('@/components/knowledge_base/menu/NavTreeMaterial')
        },
        computed: {
            ...mapGetters('navigation', ['navElements']),
            ...mapState('knowledge_base', ['materialsList'])
        },
        data() {
            return {
                docSubMenuOpen: false,
                materialSubMenuOpen: false,
                materialContextMenu: {
                    id: 'material-context-id',
                    options: [
                        {
                            name: 'Редактировать дерево',
                            slug: 'edit-tree'
                        }
                    ]
                }
            }
        },
        methods: {
            onSubMenuOpen(key, keyPath) {
                const menuKey = parseInt(key);

                if (menuKey === 2) this.materialSubMenuOpen = true;
                if (menuKey === 3) this.docSubMenuOpen = true;
            },

            onOpenMaterialContextMenu(event, item) {
                this.$refs.materialContextMenu.showMenu(event, item);
            },

            onMaterialContextMenuClick(event) {
                const option = event.option;

                if (option.slug === 'edit-tree') {
                    const EditTreeMaterial = () => import('@/components/edit-tree-material/edit-tree-material');

                    this.$modal.show(EditTreeMaterial, {}, {
                        name: 'edit-tree-material',
                        adaptive: true,
                        maxWidth: 980,
                        width: '100%',
                        height: 'auto'
                    }, {
                        'before-open': () => {
                            document.body.classList.add('body-modal');
                        },

                        'before-close': () => {
                            document.body.classList.remove('body-modal');
                        }
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    @mixin sidebar-nav-item {
        border-radius: 4px;
        padding: $nav-item-padding;
        transition: all $ease;
        &:hover {
            background-color: #616575;
        }
    }

    .sidebar {
        $block: &;

        &__nav {
            height: 100%;
            color: #fff;
            transform: translateZ(0);
            &-item {
                overflow-x: auto;
                @include sidebar-nav-item;

                &.is-opened {
                    border-radius: 0px;
                    background-color: #494d5a;
                }

                &.is-disabled {
                    .el-submenu {
                        &__title {
                            &:before {
                                visibility: hidden;
                            }
                        }
                    }
                }
            }

            &-groups {
                transition: opacity $ease;
            }

            .el {
                &-tree {
                    &-node {
                        &__content {
                            @include sidebar-nav-item;
                        }
                    }

                    &__drop {
                        &-indicator {
                            background-color: #fff;
                        }
                    }
                }

                &-loading {
                    transition: opacity $ease;
                    &-parent {
                        &--relative {
                            #{$block}__nav-loading {
                                opacity: 0;
                            }
                        }
                    }

                    &-mask {
                        align-items: flex-start;
                        justify-content: center;
                    }
                }
            }
        }
    }
</style>
